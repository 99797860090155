<template>
  <div id="app_content">
    <span v-show="authorization==1">欢迎您： {{ username }}</span>
    <nav>
      <router-link to="/">星际之家|</router-link>
      <router-link to="/login" v-show="authorization==0">登陆|</router-link>
      <router-link to="/PlayerSignUp" v-show="privilege===1">添加选手  |</router-link>
      <router-link to="/MatchSignUp"  v-show="privilege===1">添加赛事  |</router-link>
      <router-link to="/MatchLog"  v-show="privilege===1">添加比赛记录  |</router-link>
      <router-link to="/Modify"  v-show="privilege===1">修改记录  |</router-link>
      <router-link to="/reset">重置密码</router-link>
    </nav>
  </div>
  <router-view/>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      privilege: 0, // 默认权限为0
      authorization: 0, // 默认权限为0
      username:'',
    };
  },
  provide() {
    return {
      privilege: this.privilege,
      authorization: this.authorization,
      username: this.username,
      setPrivilege: this.setPrivilege,
      setAuthorization: this.setAuthorization,
    };
  },
  created() {
    this.getPrivilege();
  },
  methods: {
    setPrivilege(newPrivilege,newAuth,newName) {
      this.privilege = newPrivilege;
      this.authorization=newAuth;
      this.username=newName;
      // window.sessionStorage.setItem('Privilege', newPrivilege);
    },
    getPrivilege() {
      // 从sessionStorage中获取Privilege值
      const privilege = window.sessionStorage.getItem('Privilege');
      const authorization = window.sessionStorage.getItem('Authorization');
      const username = window.sessionStorage.getItem('userName');
      // 将字符串转换为数字
      this.privilege = privilege ? Number(privilege) : 0;
      if(authorization){
        this.authorization=1;
      }
      this.username=username||'';
    }
  }
}
</script>

<style>
body{
  background-color: #000B17;
  background-image: url("@/assets/images/bj.jpg");
  background-repeat: no-repeat;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
#app_content{
  width: 100%;
  hieght: 80px;
  display: block;
}
#app_content nav,#app_content span {
  padding: 30px;
  width: 30%;
  margin: 0 0 0 60%;
  color: #C9D3DD;
}

nav a {
  font-weight: bold;
  color: #C9D3DD;
}

nav a.router-link-exact-active {
  color: #FFC25A;
}
</style>
<script setup>
import ReSet from "@/views/ReSet.vue";
import HelloWorld from "@/components/HelloWorld.vue";
</script>