<template>
  <div id="content">
    <h1>添加选手</h1>
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <p>
      <input type="text" placeholder="赛事名称" id="MatchName" v-model="MatchName">
    </p>
<!--    <p>-->
<!--      <input type="number" v-model="SignUpState" placeholder="报名状态">-->
<!--    </p>-->
    <p>
      <button @click="SignUp">确认</button>
    </p>
  </div>
</template>

<script>
import {MatchSignUp} from "@/network/PlayerSignUp";

export default {
  name: "MatchSignUp",
  data(){
    let resMsg;
    return{
      resMsg:'',
      MatchName:'',
      SignUpState:''
    }
  },
  methods:{
    SignUp(){
      MatchSignUp(this.MatchName).then(res=>{
        // console.log(res)
        if(res.code==1000){
          this.resMsg="赛事添加成功"
        }else {
          this.resMsg="赛事添加失败"
        }
      })
    }
  }
}
</script>

