import {request} from "@/network/request";

// PlayerSignUp 添加选手
export function PlayerSignUp(NickID,NickName,Level,Race){
    return request({
        method:'post',
        url:'/api/signup',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            UserName:NickID,
            NickName:NickName,
            Level:Level,
            Race:Race
        })
    })
}

// MatchSignUp 添加选手
export function MatchSignUp(MatchName){
    return request({
        method:'post',
        url:'/api/match_up',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            MatchName:MatchName,
        })
    })
}

// MatchLog 添加比赛记录
export function MatchLog(Bo,Player1Id,UserName1,Player1,Score1,Race1,Level1,Player2Id,UserName2,Player2,Score2,Race2,Level2,MatchId,MatchName,Result){
    return request({
        method:'post',
        url:'/api/match_log',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            Bo:Bo,
            Player1Id:Player1Id,
            UserName1:UserName1,
            Player1:Player1,
            Score1:Score1,
            Race1:Race1,
            Level1:Level1,
            Player2Id:Player2Id,
            UserName2:UserName2,
            Player2:Player2,
            Score2:Score2,
            Race2:Race2,
            Level2:Level2,
            MatchId:MatchId,
            MatchName:MatchName,
            Result:Result,
        })
    })
}