import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Login from "@/views/Login.vue";
import ReSet from '../views/ReSet.vue'
import PlayerSignUp from "@/views/PlayerSignUp.vue";
import MatchSignUp from "@/views/MatchSignUp";
import MatchLog from "@/views/MatchLog";
import Modify from "@/views/Modify";
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta:{
      title:'首页'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta:{
      title:'登陆'
    }
  },
  {
    path: '/PlayerSignUp',
    name: 'PlayerSignUp',
    component: PlayerSignUp,
    meta:{
      title:'添加选手'
    }
  },
  {
    path: '/MatchSignUp',
    name: 'MatchSignUp',
    component: MatchSignUp,
    meta:{
      title:'添加赛事'
    }
  },
  {
    path: '/MatchLog',
    name: 'MatchLog',
    component: MatchLog,
    meta:{
      title:'添加比赛记录'
    }
  },
  {
    path: '/Modify',
    name: 'Modify',
    component: Modify,
    meta:{
      title:'修改'
    }
  },

  {
    path: '/reset',
    name: 'reset',
    component: ReSet,
    meta:{
      title:'重置密码'
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
// 全局前置守卫
router.beforeEach((to, from) => {
  document.title = to.meta.title
})
export default router
