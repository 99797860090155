<template>
<div id="content">
  <div>
    <h3>请选择要修改的内容</h3>
    <select name="mainSelect" id="mainSelect" v-model="mainSelect">
      <option value="userSelect">修改选手</option>
      <option value="matchSelect">修改赛事</option>
      <option value="logSelect">修改比赛记录</option>
    </select>
  </div>
  <p>
    <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
  </p>
  <div v-show="mainSelect=='userSelect'">
    <h3>请选择要修改的选手名称</h3>
    <select v-model="NickName" name="NickName" id="NickName">
      <option v-for="user in userData" :key="user.id" :value="user.NickName">
        {{ user.NickName }}
      </option>
    </select>

    <div v-show="NickName!=''">
      <h3>请在下方修改对应的内容：</h3>
      <p>
        <label for="NewUserName">游戏ID</label>
        <input type="text" v-model="NewUserName" placeholder="{{NewUserName}}" id="NewUserName">
      </p>
      <p>
        <label for="NewNickName">昵称/姓名</label>
        <input type="text" v-model="NewNickName" placeholder="{{NewNickName}}" id="NewNickName">
      </p>
      <p>
        <label for="NewLevel">评级</label>
        <input type="text" v-model="NewLevel" placeholder="{{NewLevel}}" id="NewLevel">
      </p>
      <p>
        <label for="NewRace">种族</label>
        <input type="text" v-model="NewRace" placeholder="{{NewRace}}" id="NewRace">
      </p>
      <p>
        <label for="NewPrivilege">权限</label>
        <input type="number" v-model="NewPrivilege" placeholder="{{NewPrivilege}}" id="NewPrivilege">
      </p>
      <p>
        <button @click="modifyUser">提交</button>
      </p>
    </div>

  </div>
  <div v-show="mainSelect=='matchSelect'">
    <h3>请输入要修改的赛事名称</h3>
    <select v-model="MatchName" name="selectedMatchName" id="selectedMatchName">
      <option v-for="item in matchNameData" :key="item.id" :value="item.MatchName">
        {{ item.MatchName }}
      </option>
    </select>
    <div v-show="MatchName!=''">
      <h3>请在下方修改对应的内容：</h3>
      <p>
        <label for="NewMatchName">比赛名称：</label>
        <input type="text" v-model="NewMatchName" placeholder="{{NewMatchName}}" id="NewMatchName">
        <label for="MatchStatus">比赛名称：</label>
        <input type="text" v-model="MatchStatus" placeholder="{{MatchStatus}}" id="MatchStatus">
      </p>
      <p>
        <button @click="modifyMatchName">提交</button>
      </p>
    </div>

  </div>
  <div v-show="mainSelect=='logSelect'">
    <h3>请输入要修改的记录名称</h3>
    <div>
      <p>
        <label for="MatchName">赛事名称:</label>
        <select v-model="MatchName" name="selectedMatchName" id="selectedMatchName">
          <option v-for="item in matchNameData" :key="item.id" :value="item.MatchName">
            {{ item.MatchName }}
          </option>
        </select>
      </p>
      <p>
        <label for="nicknames">选手1:</label>
        <select v-model="Player1" name="nicknames" id="nicknames">
          <option v-for="user in userData" :key="user.id" :value="user.NickName">
            {{ user.NickName }}
          </option>
        </select>
      </p>
      <p>
        <label for="selectedNickName">选手2:</label>
        <select v-model="Player2" name="selectedNickName" id="selectedNickName">
          <option v-for="user in userData" :key="user.id" :value="user.NickName">
            {{ user.NickName }}
          </option>
        </select>
      </p>
      <p>
        <button @click="MatchLogQuery">提交</button>
      </p>
    </div>
    <div v-show="MatchLogArr!=''">
      <table>
        <tr>
          <th>赛事</th>
          <th>Bo</th>
          <th>选手1</th>
          <th>得分</th>
          <th>选手2</th>
          <th>得分</th>
          <th>创建时间</th>
          <th>修改</th>
        </tr>
        <template v-for="(item,index) in MatchLogArr" :key="index">
          <tr>
            <td>{{ item.MatchName }}</td>
            <td>{{ item.Bo }}</td>
            <td>{{ item.Player1 }}</td>
            <td>{{ item.Score1 }}</td>
            <td>{{ item.Player2 }}</td>
            <td>{{ item.Score2 }}</td>
            <td>{{ item.CreateTime }}</td>
            <td><button @click="editMatch(index)">修改</button></td>
          </tr>
        </template>
      </table>
    </div>
  </div>
  <div v-show="selectedMatch!=''">
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <p>
      <label for="NewMatchName">赛事名称:</label>
      <select v-model="selectedMatch.MatchName" name="NewMatchName" id="NewMatchName">
        <option v-for="item in matchNameData" :key="item.id" :value="item.MatchName">
          {{ item.MatchName }}
        </option>
      </select>
    </p>
    <p>
      <label for="NewBo">比赛场次:</label>
      <input type="number" v-model="selectedMatch.Bo" placeholder="selectedMatch.Bo" id="NewBo">
    </p>
    <p>
      <label for="NewPlayer1">选手1:</label>
      <select v-model="selectedMatch.Player1" name="NewPlayer1" id="NewPlayer1">
        <option v-for="user in userData" :key="user.id" :value="user.NickName">
          {{ user.NickName }}
        </option>
      </select>

    </p>
    <p>
      <label for="NewScore1">选手1比分:</label>
      <input type="number" v-model="selectedMatch.Score1" placeholder="selectedMatch.Score1" id="NewScore1">
    </p>
    <p>
      <label for="NewPlayer2">选手2:</label>
      <select v-model="selectedMatch.Player2" name="selectedNickName" id="selectedNickName">
        <option v-for="user in userData" :key="user.id" :value="user.NickName">
          {{ user.NickName }}
        </option>
      </select>
    </p>
    <p>
      <label for="NewScore2">选手2比分:</label>
      <input type="number" v-model="selectedMatch.Score2" placeholder="selectedMatch.Score2" id="NewScore2">
    </p>
    <p>
      <button @click="ModifyMatchLogData">提交比赛修改记录</button>
    </p>
  </div>


</div>
</template>

<script>
import {reqGetUserData} from "@/network/home";
import {ref} from "vue";
import {
  modifyMatchData,
  modifyUserData,
  modifyMatchLogQuery, modifyMatchLogData
} from "@/network/modify";
let userData = ref([])
let matchNameData = ref([])
let matchPlayer = ref([])
let matchLog = ref([])

export default {
  name: "Modify",
  created() {
    this.getData()
  },
  data(){
    return{
      resMsg:'',
      mainSelect:'',
      NickName:'',
      MatchName:'',
      UserId:0,
      MatchID:0,
      MatchStatus:1,
      NewUserName:'',
      NewNickName:'',
      NewLevel:'',
      NewRace:'',
      NewPrivilege:'',
      NewMatchName:'',
      userData,
      matchNameData,
      matchPlayer,
      matchLog,
      Player1:'',
      Player2:'',
      Player1ID:0,
      Player2ID:0,
      MatchLogArr:[],
      selectedMatch:'',
    }
  },
  methods:{
    getData() {
      reqGetUserData().then(res => {
        if (res){
          // console.log(res);
          if('code' in res){
            if (res.code == 1000) {
              userData.value=res.data.ReturnUserMsg
              matchNameData.value=res.data.ReturnMatchName
              matchPlayer.value=res.data.ReturnMatchPlayer
              matchLog.value=res.data.ReturnMatchLog
            } else if (res.code == 1006 || res.code == 1007) {
              this.resMsg = res.msg
              setTimeout(() => {
                window.sessionStorage.removeItem('Authorization')
                this.$router.push('/login')
              }, 300) // 延迟5秒
            } else {
              this.resMsg = res.msg
            }
          }
        }
        else {
          this.resMsg = "接口访问失败"
        }
      })
    },
    modifyUser(){
      modifyUserData(this.UserId,this.NewUserName,this.NewNickName,this.NewLevel,this.NewRace,this.NewPrivilege).then(res=>{
        // console.log(res);
        if('code' in res){
          if(res.code==1000){
            this.resMsg="修改成功"
          }else {
            this.resMsg="修改失败"
          }
        }
      })
    },
    modifyMatchName(){
      modifyMatchData(this.MatchID,this.NewMatchName,this.MatchStatus).then(res=>{
        // console.log(res);
        if('code' in res){
          if(res.code==1000){
            this.resMsg="修改成功"
          }else {
            this.resMsg="修改失败"
          }
        }
      })
    },
    MatchLogQuery(){
      modifyMatchLogQuery(this.MatchID,this.Player1ID,this.Player2ID).then(res=>{
        console.log(res);
        if('code' in res){
          if(res.code==1000){
            this.MatchLogArr=res.data;
          }else {
            this.resMsg="修改失败"
          }
        }
      })
    },
    editMatch(index){
      this.selectedMatch = { ...this.MatchLogArr[index] };
      // console.log(this.selectedMatch);
    },
    ModifyMatchLogData(){
      const user = this.userData.find(user => user.NickName === this.selectedMatch.Player1);
      this.selectedMatch.Player1Id = user ? user.id : null;
      this.selectedMatch.Race1 = user ? user.Race : null;
      this.selectedMatch.Level1 = user ? user.Level : null;
      this.selectedMatch.UserName1 = user ? user.UserName : null;

      const user2 = this.userData.find(user2 => user2.NickName === this.selectedMatch.Player2);
      this.selectedMatch.Player2Id = user2 ? user2.id : null;
      this.selectedMatch.Race2 = user2 ? user2.Race : null;
      this.selectedMatch.Level2 = user2 ? user2.Level : null;
      this.selectedMatch.UserName2 = user2 ? user2.UserName : null;
      // 生成对抗和结果
      // this.selectedMatch.Confrontation=this.selectedMatch.Race1+"v"+this.selectedMatch.Race2;
      const match = this.matchNameData.find(match => match.MatchName === this.selectedMatch.MatchName);
      this.selectedMatch.MatchId = match ? match.id : null;
      if (this.selectedMatch.Bo==this.selectedMatch.Score1+this.selectedMatch.Score2){
        console.log(this.selectedMatch.Player1Id,this.selectedMatch.Player2Id);
        //传递修改参数
        modifyMatchLogData(this.selectedMatch.id,this.selectedMatch.Bo,
            this.selectedMatch.Player1Id,this.selectedMatch.Player1,this.selectedMatch.UserName1,this.selectedMatch.Score1,this.selectedMatch.Race1,this.selectedMatch.Level1,
            this.selectedMatch.Player2Id,this.selectedMatch.Player2,this.selectedMatch.UserName2,this.selectedMatch.Score2,this.selectedMatch.Race2,this.selectedMatch.Level2,
        this.selectedMatch.MatchId,this.selectedMatch.MatchName).then(res=>{
          if('code' in res){
            if(res.code==1000){
              this.resMsg="修改成功"
            }else {
              this.resMsg="修改失败"
            }
          }
        })

      }else {
        this.resMsg="分值输入有误,选手比赛分值总和应等于比赛总分"
      }
      // console.log(this.selectedMatch);
    }
  },
  watch:{
    mainSelect(newSelect){
      console.log(newSelect);
    },
    // 监听 selectedNickName 的变化
    NickName(newNickName) {
      // 查找对应的用户对象
      const user = this.userData.find(user => user.NickName === newNickName);
      // 如果找到了用户，更新 selectedUserId
      this.UserId = user ? user.id : null;
      this.NewRace=user?user.Race:null;
      this.NewLevel=user?user.Level:null;
      this.NewUserName=user?user.UserName:null;
      this.NewPrivilege=0;
      this.NewNickName=newNickName;
      console.log(this.UserId);
      // console.log(this.Player1Id,this.Race1,this.Level1)
    },
    MatchName(newMatchName){
      // 查找对应的用户对象
      const match = this.matchNameData.find(match => match.MatchName === newMatchName);
      this.MatchID = match ? match.id : null;
      this.NewMatchName=newMatchName;
      // console.log(this.MatchID);
    },
    Player1(newPlayer){
      // 查找对应的用户对象
      const user = this.userData.find(user => user.NickName === newPlayer);
      // 如果找到了用户，更新 selectedUserId
      this.Player1ID = user ? user.id : null;
      // console.log(this.Player1ID);
    },
    Player2(newPlayer){
      // 查找对应的用户对象
      const user = this.userData.find(user => user.NickName === newPlayer);
      // 如果找到了用户，更新 selectedUserId
      this.Player2ID = user ? user.id : null;
      // console.log(this.Player2ID);
    },
  },
}
</script>

<style scoped>
p span, p label{
  display: block;
  width: 100%;
}
</style>