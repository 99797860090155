import {request} from "@/network/request";

export function modifyUserData(UserID,UserName,NickName,Level,Race,Privilege){
    return request({
        method:'post',
        url:'/api/ModifyUser',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            id:UserID,
            UserName:UserName,
            NickName:NickName,
            Level:Level,
            Race:Race,
            Privilege:Privilege,
        })
    })
}

//modifyMatchData
export function modifyMatchData(id,MatchName,MatchStatus){
    return request({
        method:'post',
        url:'/api/ModifyMatchName',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            id:id,
            MatchName:MatchName,
            SignUp:MatchStatus,
        })
    })
}

// modifyMatchLogData
export function modifyMatchLogQuery(matchID,Player1ID,Player2ID){
    return request({
        method:'post',
        url:'/api/MatchLogQuery',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            MatchNameID:matchID,
            Player1ID:Player1ID,
            Player2ID:Player2ID,
        })
    })
}

// modifyMatchLogData
export function modifyMatchLogData(id,Bo,Player1Id,Player1,UserName1,Score1,Race1,Level1,Player2Id,Player2,UserName2,Score2,Race2,Level2, MatchId,MatchName){
    console.log(id,Bo,Player1Id,Player1,UserName1,Score1,Race1,Level1,Player2Id,Player2,UserName2,Score2,Race2,Level2, MatchId,MatchName);
    return request({
        method:'post',
        url:'/api/ModifyMatchLog',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            id:id,
            Bo:Bo,
            Player1Id:Player1Id,
            Player1:Player1,
            UserName1:UserName1,
            Score1:Score1,
            Race1:Race1,
            Level1:Level1,
            Player2Id:Player2Id,
            Player2:Player2,
            UserName2:UserName2,
            Score2:Score2,
            Race2:Race2,
            Level2:Level2,
            MatchId:MatchId,
            MatchName:MatchName
        })
    })
}