import {request} from "@/network/request";

// reqLogin
export function reqLogin(name,password){
    return request({
        method:'post',
        url:'/api/login',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            userName:name,
            passWord:password,
        })
    })
}

export function resetPass(username,password,newpass,renewpass){
    return request({
        method:'post',
        url:'/api/reset',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            UserName:username,
            PassWord:password,
            NewPassWord:newpass,
            ReNewPassWord:renewpass,
        })
    })
}

export function reqGetData(){
    return request({
        method:'get',
        url:'/api/msg',
    })
}

export function reqGetUserData(){
    return request({
        method:'get',
        url:'/api/UserData',
    })
}

//选手详情

export function UserQuery(Player1Id){
    return request({
        method:'post',
        url:'/api/UserQuery',
        headers:{
            'Content-Type': 'application/json'
        },
        data:JSON.stringify({
            id:Player1Id,
        })
    })
}