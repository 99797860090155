<template>
<div id="content">
  <h1>重置密码</h1>
  <p>
    <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
  </p>
  <p>
<!--    <label for="username"></label>-->
    <input type="text" placeholder="账号" id="username" v-model="username">
  </p>
  <p>
    <input type="password" v-model="password" placeholder="原密码">
  </p>
  <p>
    <input type="password" v-model="newpass" placeholder="新密码">
  </p>
  <p>
    <input type="password" v-model="renewpass" placeholder="再次输入新密码">
  </p>
  <p>
    <button @click="reset">确认</button>
  </p>


</div>
</template>

<script>
import {resetPass} from "@/network/home";
export default {
  name: "ReSet",
  data(){
    let resMsg;
    return{
      resMsg:'',
      username:'',
      password:'',
      newpass:'',
      renewpass:'',
    }
  },
  methods:{
    reset(){
      resetPass(this.username,this.password,this.newpass,this.renewpass).then(res=>{
        console.log(res)
        if(res.code==1000){
          this.resMsg="密码修改成功"
        }else {
          this.resMsg="密码修改失败"
        }
      })
    }
  }
}
</script>
