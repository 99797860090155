<template>
  <div id="content">
    <h1>添加选手</h1>
    <p>
      <span v-if="resMsg" style="color: red;">{{ resMsg }}</span>
    </p>
    <p>
      <input type="text" placeholder="选手ID" id="NickID" v-model="NickID">
    </p>
    <p>
      <input type="text" placeholder="选手昵称" id="NickName" v-model="NickName">
    </p>
    <p>
      <input type="text" v-model="Level" placeholder="评级">
    </p>
    <p>
      <select name="Race" id="Race" v-model="Race">
        <option value="T">人族(T)</option>
        <option value="P">神族(P)</option>
        <option value="Z">虫族(Z)</option>
      </select>
<!--      <input type="text" v-model="Race" placeholder="种族">-->
    </p>
    <p>
      <button @click="SignUp">确认</button>
    </p>
  </div>
</template>

<script>
import {PlayerSignUp} from "@/network/PlayerSignUp";

export default {
  name: "PlayerSignUp",
  data(){
    let resMsg;
    return{
      resMsg:'',
      NickID:'',
      NickName:'',
      Level:'',
      Race:'',
    }
  },
  methods:{
    SignUp(){
      PlayerSignUp(this.NickID,this.NickName,this.Level,this.Race).then(res=>{
        console.log(res)
        if(res.code==1000){
          this.resMsg="选手添加成功"
        }else {
          this.resMsg="选手添加失败"
        }
      })
    }
  }
}
</script>

